/*! DFC AB Testing Framework: 2.0.0.2024-10-10T19:37:14.501Z */
"use strict";

// framework v2 helper functions / bootstrapping
if (!function lsTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}()) {
  throw new Error('DFC: localStorage required.');
}
(() => {
  const dfc = window.dfc; // for minification

  const doNotTrack = () => {
    localStorage.setItem('dfc-do-not-track', 'true');
    dfc.DO_NOT_TRACK = true;
  };
  dfc.DO_NOT_TRACK = localStorage.getItem('dfc-do-not-track') === 'true';
  if (/\b(bot|crawler|spider|slurp)\b/i.test(navigator.userAgent)) {
    doNotTrack();
  }
  const searchParams = new URLSearchParams(location.search);

  // first we reset params if needed
  if (searchParams.get('dfc-reset') || searchParams.get('dfc-reset-all')) {
    for (const key in localStorage) {
      if (/^dfc[-:]/.test(key)) {
        localStorage.removeItem(key);
      }
    }
  }

  // then we recoup the params set from preview link
  const prefix = 'dfc-exp-';
  try {
    const force = new RegExp(`^${prefix}`);
    searchParams.forEach((value, key) => {
      if (force.test(key)) {
        localStorage.setItem(key, value);
        doNotTrack();
      }
    });
  } catch (e) {
    // do nothing b/c old browsers don't support URLSearchParams
    // eslint-disable-next-line no-console
    console.error(e);
  }

  // Reset disable if searchParams.get('dfc-env')
  if (searchParams.get('dfc-env')) {
    localStorage.removeItem('dfc-disable');
  }

  // iife flags for closure
  const [env, disable] = (() => {
    return ['env', 'disable'].map(key => {
      const ns = `dfc-${key}`;
      const value = searchParams.get(ns) || getWithExpiry(ns);
      if (value) {
        setWithExpiry(ns, value);
      }
      dfc[key] = value;
      return value;
    });
  })();

  // then we disable and adjust based on config
  if (disable) {
    throw new Error('dfc: disabled');
  }

  // add do not track flag for any env
  if (env) doNotTrack();
  if (!dfc.onExp) {
    dfc.onExp = fn => {
      dfc.experiments.forEach(arr => fn(arr[0], arr[1]));
      dfc.trackers.push(fn);
    };
  }
  const loadScript = (url, callback, onerror) => {
    // log('loadScript', url);
    const script = document.createElement('script');
    const scripts = document.getElementsByTagName('script')[0];
    script.src = url;
    if (callback) script.onload = () => callback(script);
    // eslint-disable-next-line no-console
    script.onerror = onerror || (e => console.error('dfc.loadScript', url, e));
    scripts.parentNode.insertBefore(script, scripts);
  };
  dfc.loadScript = src => new Promise(loadScript.bind(null, src));
  dfc.loadLink = url => {
    // log('loadLink', url);
    const head = document.getElementsByTagName('HEAD')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    head.appendChild(link);
  };
  if (env === 'dev') {
    dfc.host = `https://localhost:8888`;
  } else if (!env || env === 'main') {
    // dfc.host = `https://digitalfuelcapital.pages.dev`;
    dfc.host = `https://digitalfueloptimize.com`;
  } else {
    // shouldn't be possible to get here but just in case
    if (!env) throw new Error('DFC ENV required for non-main env');
    dfc.host = `https://${env}.digitalfuelcapital.pages.dev`;
  }

  /****************************************************************************
   * Pivot off environment flag for loading preview links
   *****************************************************************************/

  if (/^(staging|dev|(pr|release)-\d+)$/.test(env) && !window._dfcEnvLoaded) {
    window._dfcEnvLoaded = {
      env
    };
    const client = (() => {
      const script = document.querySelector('script[src*="digitalfueloptimize.com/"], script[src*="digitalfuelcapital.pages.dev/"], script[src*="dgjvwr5wei7tc.amplifyapp.com/"]');
      const src = (script && script.getAttribute('src') || '//./').split('//')[1];
      const client = src.split('/')[1];
      return client;
    })();

    // if we're not on the desired env then load it
    loadScript(`${dfc.host}/${client}/index.js`);
    throw new Error(`dfc-env set - stopping execution to load ${env}`);
  }

  // prevent double framework load from timex
  if (dfc.loaded) {
    throw new Error(`window.dfc.loaded===true - don't load twice`);
  }
  dfc.loaded = true;

  // Logging
  const noop = () => {};
  dfc.log = noop;
  dfc.error = noop;
  if (env) {
    // eslint-disable-next-line no-console
    dfc.log = console.log.bind(console, 'dfc');
    // eslint-disable-next-line no-console
    dfc.error = console.error.bind(console, 'dfc');
    dfc.log('running v2.js version', JSON.stringify({
      env: env,
      inline: dfc.version
    }));
    if (env === 'dev') {
      loadScript(`https://localhost:35729/livereload.js?snipver=1`);
    }
    loadScript(`${dfc.host}/notify.js`);
  }
  async function wrap(str, fn, ...args) {
    try {
      return await fn(...args);
    } catch (e) {
      if (e) {
        dfc.error(str, e);
      }
    }
  }
  dfc.wrap = wrap;
  async function safe(fn, ...args) {
    try {
      return await fn(...args);
    } catch (e) {
      if (env) {
        // eslint-disable-next-line no-console
        console.error('DFC ERROR', e);
      }
    }
  }
  dfc.safe = safe;
  dfc.getCookie = function (cname) {
    const nameEQ = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  // domReady
  dfc.ready = new Promise(res => {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      res();
    } else {
      document.addEventListener('DOMContentLoaded', res);
    }
  });

  // window load
  dfc.load = new Promise(res => {
    dfc.log('document.readyState', document.readyState);
    if (document.readyState === 'complete') {
      res();
    } else {
      window.addEventListener('load', res);
    }
  });
  let stop = false;
  dfc.load.then(() => {
    dfc.log('page loaded');
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => {
      stop = true;
    }, 250);
  });

  /**
   * Add a style tag to the head
   * @param {*} css
   * @returns {HTMLStyleElement}
   * @example
   * dfc.addStyle(`.el-class { color: red; }`);
   * @example with id for removal later
   * const styleId = dfc.addStyle('body {display:none}');
   * await dfc.load
   * dfc.qs(`#${styleId}`).remove();
   * @example remove after load event
   * dfc.load.then(() => {
   * const $style = dfc.qs(`#${styleId}`);
   * $style && $style.remove();
   * });
   */
  let _idx = 0;
  dfc.addStyle = css => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.id = `dfc-style-${_idx++}`;
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    return style.id;
  };
  const jq = (callback, onError) => {
    const $ = window.jQuery;
    if ($ && $.fn && $.fn.jquery) safe(callback.bind(null, $));else if (stop) onError(new Error('dfc.jQuery jQuery not found'));else window.requestAnimationFrame(jq.bind(null, callback, onError));
  };

  // weird cache so we don't poll all the time
  let jQuery = null;
  Object.defineProperty(dfc, 'jQuery', {
    get: function () {
      return jQuery || (jQuery = new Promise(jq.bind(null)));
    }
  });
  const raf = (selector, callback, onError) => {
    const $els = document.querySelectorAll(selector);
    const found = !!$els.length;
    if (found) return safe(callback.bind(null, $els));
    if (stop) onError && onError();else window.requestAnimationFrame(raf.bind(null, selector, callback, onError));
  };

  /**
   * @param {*} selector
   * @returns {Promise<HTMLElement[]>}
   * @example
   * const els = await dfc.raf('.el-class');
   * els.forEach(el => el.style.color = 'red');
   */
  dfc.raf = selector => new Promise(raf.bind(null, selector));

  /**
   * @param {*} selector
   * @returns {Promise<HTMLElement>}
   * @example
   * const el = await dfc.find('#my-id');
   * el.style.color = 'red';
   */
  dfc.find = selector => dfc.raf(selector).then($els => $els[0]);
  dfc.$raf = selector => new Promise(jq.bind(null)).then(async $ => {
    const $els = await dfc.raf(selector);
    return $($els);
  });
  dfc.qs = document.querySelector.bind(document);
  dfc.qsa = (selector, context = document) => Array.from(context.querySelectorAll(selector));
  dfc.createEl = str => {
    const div = document.createElement('div');
    div.innerHTML = str.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  };
  dfc.live = (selector, event, callback, context) => {
    // helper for enabling IE 8 event bindings
    function addEvent(el, type, handler) {
      if (el.attachEvent) el.attachEvent('on' + type, handler);else el.addEventListener(type, handler);
    }
    // live binding helper using matchesSelector
    function live(selector, event, callback, context) {
      addEvent(context || document, event, e => {
        let found,
          el = e.target || e.srcElement;
        while (el && el.matches && el !== context && !(found = el.matches(selector))) el = el.parentElement;
        if (found) callback.call(el, e);
      });
    }
    live(selector, event, callback, context);
  };

  // eslint-disable-next-line no-restricted-globals
  dfc.sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  dfc.waitFor = async f => {
    let resp;
    while (!(resp = f()) && !stop) await new Promise(requestAnimationFrame);
    if (resp) return resp;else throw new Error('dfc.waitFor: f() not true by stop', f);
  };
  dfc.waitForever = async f => {
    let resp;
    while (!(resp = f())) await new Promise(requestAnimationFrame);
    return resp;
  };
  dfc.waitForSeconds = async (f, seconds = 5) => {
    let resp,
      stop = false;
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => {
      stop = true;
    }, seconds * 1000);
    while (!(resp = f()) && !stop) await new Promise(requestAnimationFrame);
    return resp;
  };

  // dfc.removeElementById = (id) => {
  //   const elem = document.getElementById(id);
  //   return elem && elem.parentNode.removeChild(elem);
  // };

  // dfc.setParam = (str, k, v) => {
  //   const url = (() => {
  //     try {
  //       return new URL(str);
  //     } catch (e) {
  //       return new URL(str, location.href);
  //     }
  //   })();
  //   url.searchParams.set(k, v);
  //   return url.toString();
  // };

  // dfc.$setParam = ($a, k, v) => {
  //   const href = $a.attr('href');
  //   return $a.attr('href', dfc.setParam(href, k, v));
  // };

  // cspell:ignore cvalue, exdays
  // function setCookie(cname, cvalue, exdays) {
  //   const d = new Date();
  //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  //   const expires = 'expires=' + d.toUTCString();
  //   document.cookie = cname + '=' + cvalue + ';' + expires + ';path`=/';
  // }

  /**
   * Hoisted Helper Functions
   */

  async function run(row, fn, ...args) {
    // weird hack to paste run in console when in dev
    if (typeof row === 'function') {
      // eslint-disable-next-line no-console
      console.error('The DFC files should only be utilized after they have undergone compilation. This code ' + 'is intended to serve as a starting point, it should not be implemented as is and ' + 'should be refactored before being integrated into production.');
      return env && dfc.wrap('run', row, ...[fn, ...args]);
    }
    const {
      experiment,
      expId,
      status
    } = row;
    const key = `dfc-exp-${expId}`;
    dfc.friendly[expId] = row;
    // set 1 for 100%s
    if (status === '100%' && parseInt(localStorage.getItem(key), 10) !== 1) {
      localStorage.setItem(key, 1);
    }

    // run everything on non main envs
    if (env && env !== 'main') {
      return dfc.wrap(experiment, fn, ...args);
    } else {
      // on deck run only when env flag set:
      if (status === 'ONDECK' && env === 'main') return dfc.wrap(experiment, fn, ...args);
      // on main, run live and 100%s
      if (status === 'LIVE' || status === '100%') return dfc.wrap(experiment, fn, ...args);
    }
  }
  dfc.run = run;
  dfc.friendly = {};
  dfc.prebucket = (id, variations = 2) => {
    let idx = window.dfc(`prebucket-${id}`, variations);
    if (/^100-/.test(id)) idx = 1;
    localStorage.setItem(`dfc-exp-${id}`, idx);
    return {
      idx,
      activate: () => window.dfc(id, variations)
    };
  };

  /**
   * Optimize and Experience Linking
   */

  dfc.shouldIgnoreRegex = /^(100|preview|prebucket|sku|ignore|todo|ondeck)/i;
  dfc.shouldIgnore = id => {
    if (!id) {
      dfc.log('dfc.shouldIgnore: ID IS NULL', id);
      return true;
    }
    if (dfc.DO_NOT_TRACK) {
      dfc.log('dfc.shouldIgnore: DO_NOT_TRACK', id);
      return true;
    }
    if (dfc.shouldIgnoreRegex.test(id)) {
      dfc.log('dfc.shouldIgnore: ignore', id);
      return true;
    }
    return false;
  };
  dfc.trackExperiences = async ({
    dedupeMs = 1000 * 60 * 60 * 12,
    onReady = false
  } = {}) => {
    // https://developers.google.com/analytics/devguides/collection/ga4/integration
    await dfc[onReady ? 'ready' : 'load'];
    dfc.onExp((id, idx) => {
      if (dfc.shouldIgnore(id)) return;
      if (dedupeMs > 1) {
        // hack to confirm valid number
        const ns = `dfc-ga4exp-${id}`;
        const value = dfc.getWithExpiry(ns);
        if (parseInt(value, 10) === parseInt(idx, 10)) return;
        dfc.setWithExpiry(ns, idx, dedupeMs);
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('event', 'experience_impression', {
        // Replace the value with the Experiment-variant ID
        exp_variant_string: `DFC-${id}-${idx}`
      });
    });
  };
  dfc.trackClarity = async () => {
    await dfc.load;
    if (!window.clarity) return;
    /*global clarity*/

    const activated = [];
    dfc.onExp((id, idx) => {
      if (dfc.shouldIgnore(id)) return;
      activated.push([id, idx]);
    });
    if (activated.length) {
      // prettier-ignore
      clarity('set', 'experiment', activated.map(([id, idx]) => `${id}:v${idx}`));
    }
    activated.push = ([id, idx]) => {
      clarity('set', 'experiment', `${id}:v${idx}`);
    };
  };

  // call like this: dfc.track('exp1-right-click');
  dfc.track = async _name => {
    dfc.log('dfc.track: ', _name);
    if (dfc.DO_NOT_TRACK) return dfc.log('dfc.DO_NOT_TRACK');
    await dfc.load; // wait till load

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('event', 'dfc_track_' + _name.toLowerCase().replace(/[-\s]+/g, '_').replace(/[^a-z0-9_]/g, '').replace(/__+/g, '_').replace(/^_|_$/g, ''));
  };
  dfc.setWithExpiry = setWithExpiry;
  function setWithExpiry(key, value, ms = 1000 * 60 * 30) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ms
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  dfc.getWithExpiry = getWithExpiry;
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
})();
// patch run to only activate on timex.com
dfc._run = dfc.run;
dfc.run = function (...args) {
  // if (/timex\.com/gi.test(location.hostname)) {
  if (/timex\.com|timexstorefront\.myshopify\.com/gi.test(location.hostname)) {
    return dfc._run(...args);
  } else {
    dfc.log('dfc.timex.js patch - experiments only on timex.com');
  }
};
dfc.trackClarity();
dfc.trackExperiences();
"use strict";

// https://digitalfueltesting.atlassian.net/browse/CRO-1384
// CRO-1384 - A - PDP - Hide Wishlist
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1384-a-pdp-hide-wishlist.js",
  "expId": "ondeck-CRO-1384-a-pdp-hide-wishlist",
  "jiraCard": "CRO-1384"
}, async () => {
  // Prebucket
  const {
    idx,
    activate
  } = dfc.prebucket('ondeck-CRO-1384-a-pdp-hide-wishlist');
  let isTestActive = false;
  if (idx === 1) {
    dfc.addStyle(` .account-header-modal__body .button.button--action-outline.button--full-between { display: none !important; } `);
  }
  if (location.pathname.includes('/products/')) {
    // Activate the experiment
    activate();
    isTestActive = true;
    if (idx === 0) {
      await dfc.load;
      // Find all the wishlist button and add the tracking event
      const $wishlistButtons = await dfc.raf('wishlist-button-block button');

      // Check if the wishlist buttons exist
      if (!$wishlistButtons) return;
      $wishlistButtons.forEach(el => {
        el.addEventListener('click', () => {
          dfc.track('CRO-1384-wishlist-button-click');
        });
      });
    } else if (idx === 1) {
      dfc.addStyle(` wishlist-button-block { display: none !important; } `);
    }
  }

  // Mini Cart
  const $miniCartNav = await dfc.find('hh-button.header__account-link');
  let isMiniCartNavClicked = false;

  // If the minicart nav have been clicked, activate the experiment
  $miniCartNav.addEventListener('click', () => {
    if (isMiniCartNavClicked) return;
    isMiniCartNavClicked = true;
    if (!isTestActive) {
      activate();
      isTestActive = true;
    }
  });
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1387
// CRO-1387 - A - All - Move Popup
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1387-a-all-move-popup.js",
  "expId": "ondeck-CRO-1387-a-all-move-popup",
  "jiraCard": "CRO-1387"
}, async () => {
  // Only run on desktop
  if (window.innerWidth < 990) return;
  await dfc.ready;
  const {
    idx,
    activate
  } = dfc.prebucket('ondeck-CRO-1387-a-all-move-popup');
  if (idx === 1) {
    if (location.pathname.includes('/products/')) {
      dfc.addStyle(` [aria-label="Get 15% Off Open Form"] { left: initial !important; right: 5px !important; bottom: 130px !important; } `);
    } else {
      dfc.addStyle(` [aria-label="Get 15% Off Open Form"] { left: initial !important; right: 5px !important; } `);
    }
  }

  // Check if the klaviyoOnsite key exists in local storage
  if (localStorage.getItem('klaviyoOnsite')) {
    // Parse the JSON stored in the klaviyoOnsite key
    const klaviyoData = JSON.parse(localStorage.getItem('klaviyoOnsite'));

    // Check if viewedForms.modal.disabledTeasers is an empty object
    if (klaviyoData.viewedForms && klaviyoData.viewedForms.modal && klaviyoData.viewedForms.modal.disabledTeasers && Object.keys(klaviyoData.viewedForms.modal.disabledTeasers).length === 0) {
      dfc.log('viewedForms.modal.disabledTeasers is an empty object');
      activate();
    }
  } else {
    dfc.log('klaviyoOnsite key not found in local storage');
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1532
// CRO-1532 - A - PLP - Sort by Bestseller
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1532-a-plp-sort-by-bestseller.js",
  "expId": "ondeck-CRO-1532-a-plp-sort-by-bestseller",
  "jiraCard": "CRO-1532"
}, async () => {
  // Only run on /collections/mens-watches/ or /collections/womens-watches/ but not on /collections/mens-watches-* or /collections/womens-watches-*
  const regex = /\/collections\/(mens|womens)-watches\/?$/;
  if (!regex.test(location.pathname)) return;
  const url = new URL(location.href);
  dfc.log('CRO-1532: url', url);
  const idx = window.dfc('ondeck-CRO-1532-a-plp-sort-by-bestseller');
  if (idx === 0) return;

  // Does the url already have a sort_by query param?
  if (url.searchParams.has('sort_by')) return;
  url.searchParams.set('sort_by', 'best-selling');
  location = url.href;
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1541
// CRO-1541 - A - PDP - Hide Zero Stars
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1541-a-pdp-hide-zero-stars.js",
  "expId": "ondeck-CRO-1541-a-pdp-hide-zero-stars",
  "jiraCard": "CRO-1541"
}, async () => {
  // Only run on PDP
  if (!location.pathname.includes('/products/')) return;
  await dfc.load;
  const $starsText = await dfc.raf('.yotpo-reviews-star-ratings-widget .yotpo-sr-bottom-line-text');
  dfc.log('Stars:', $starsText);
  dfc.log('Stars Count:', $starsText[0].textContent.includes('0'));

  // Check if the starsText text has 0.0 in it
  if (!$starsText[0].textContent.includes('0')) return;
  const idx = window.dfc('ondeck-CRO-1541-a-pdp-hide-zero-stars');
  if (idx === 0) return;
  dfc.addStyle(` .product-details__content-desktop .product-details__review-stars { display: none; } #product-yotpo-reviews { display: none; } `);
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1572
// CRO-1572 - A - All - AA
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "LIVE",
  "experiment": "CRO-1572-a-all-aa.js",
  "expId": "CRO-1572-a-all-aa",
  "jiraCard": "CRO-1572"
}, async () => {
  await dfc.ready;
  const idx = window.dfc('CRO-1572-a-all-aa');
  // eslint-disable-next-line no-console
  console.log({
    idx
  });
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1617
// CRO-1617 - A - PDP - Spec Redesign
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1617-a-pdp-spec-redesign.js",
  "expId": "ondeck-CRO-1617-a-pdp-spec-redesign",
  "jiraCard": "CRO-1617"
}, async () => {
  // On PDP only
  if (!location.pathname.includes('/products/')) return;
  await dfc.ready;
  const $productDescriptionContainer = await dfc.find('.product-description');
  dfc.log($productDescriptionContainer);
  const idx = window.dfc('ondeck-CRO-1617-a-pdp-spec-redesign');
  if (idx === 0) return;

  // Add styles
  dfc.addStyle(` .spec-single-column { columns: 1 !important; } .product-description { flex-direction: column; gap: 40px !important; } .product-description__top-container { display: block; margin-bottom: 20px; } .product-description__bottom-container { display: flex; flex-direction: column; margin-bottom: 20px; } .product-description__bottom-container .product-description__title { margin-bottom: 0 !important; } .product-description__main-content.img-container { display: none; max-width: 1000px; padding: 20px 40px 20px 0; } .product-description__main-content.img-container .product-gallery__media-image-wrapper { height: auto; } .product-description__main-content.img-container img { max-width: 500px; } @media (min-width: 768px) { .product-description__top-container, .product-description__bottom-container { margin-bottom: 0; } .product-description__top-container > .product-description__feature-container { margin-top: auto; margin-bottom: auto; } .product-description__top-container > .product-description__main-content > product-description-truncated > div { padding-right: 40px; } .product-description__top-container, .product-description__inner-content { display: flex; flex-direction: row; } .product-description__main-content.img-container { display: block; } } `);

  // Utility function to find and create sections
  const findAndCreateSection = (descriptionGroups, sectionTitle, terms) => {
    const indexes = Array.from(descriptionGroups).reduce((acc, group, index) => {
      const termText = group.querySelector('.product-description__description-term').textContent;
      const detailElement = group.querySelector('.product-description__description-detail');
      const detailText = (detailElement ? detailElement.textContent : '').trim();
      const isInvalidDetail = !detailText || ['n/a', 'N/A', 'Not Applicable', ''].includes(detailText);
      if (terms.some(term => termText.includes(term)) && !isInvalidDetail) {
        acc.push(index);
      }
      return acc;
    }, []);
    if (indexes.length) {
      const container = document.querySelector('.product-description__bottom-container .product-description__feature-list');
      if (container) {
        const sections = indexes.map(index => descriptionGroups[index].outerHTML).join('');
        container.prepend(carouselSection(sectionTitle, sections));
      }
    }
  };

  // Carousel section creation function
  const carouselSection = (title, descriptionBlocks) => dfc.createEl(` <li class="product-description__feature-item"> <hh-accordion class="product-description__feature-item-details"> <button type="button" class="flex product-description__feature-item-summary active" aria-expanded="true"> <span>${title}</span> <span class="product-description__feature-item-summary-icon"> <svg width="12" height="12" viewBox="0 0 12 12" aria-hidden="true"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron-down-small"></use> </svg> </span> </button> <div style="display: block; max-height: 254px;"> <dl class="product-description__description-list spec-single-column"> ${descriptionBlocks} </dl> </div> </hh-accordion> </li>`);

  // Function to create sections in the product description
  function createSection(container) {
    // Move children of the container into a new div
    const $newProductDescriptionContainer = dfc.createEl('<div class="product-description__top-container"></div>');
    while (container.firstChild) {
      $newProductDescriptionContainer.appendChild(container.firstChild);
    }
    container.appendChild($newProductDescriptionContainer);

    // Get the product gallery media image grid and clone the first wrapper
    const $productGalleryMediaImageWrapper = document.querySelector('.product-gallery__media-image-grid .product-gallery__media-image-wrapper');
    if (!$productGalleryMediaImageWrapper) return;

    // Add a second container for the feature list
    const $featureList = dfc.createEl(` <div class="product-description__bottom-container"> <h2 class="product-description__title">Specifications</h2> <div class="product-description__inner-content"> <div class="product-description__main-content img-container"> ${$productGalleryMediaImageWrapper.outerHTML} </div> <div class="product-description__feature-container"> <ul class="product-description__feature-list"></ul> </div> </div> </div>`);
    container.appendChild($featureList);

    // Add footer content
    const $deliverReturns = dfc.createEl(` <div class="product-description__footer-container"> <h2 class="product-description__title">Delivery & Returns</h2> <p class="product-description__description">Free Standard Shipping on most orders. Fan Shop orders do not qualify for free shipping. Buy two straps get free Standard Shipping. Get your refund faster with easy online returns! Mail your items back with our return label within 30 days.</p> </div>`);
    container.appendChild($deliverReturns);
    const $featuredSpecContainerList = container.querySelector('.product-description__feature-container .product-description__feature-list');
    dfc.log('$featuredSpecContainerList', $featuredSpecContainerList);
    const $featuredSpecItem = $featuredSpecContainerList.querySelector('.product-description__feature-item');
    if (!$featuredSpecItem) return;
    const $clonedFeaturedSpecItem = $featuredSpecItem.cloneNode(true);
    $featuredSpecItem.remove();

    // Remove the "Delivery & Returns" section if it exists
    const $deliveryReturns = $featuredSpecContainerList.querySelector('.product-description__feature-item-summary span');
    if ($deliveryReturns && $deliveryReturns.textContent.includes('Delivery & Returns')) {
      $deliveryReturns.closest('.product-description__feature-item').remove();
    }
    dfc.log($clonedFeaturedSpecItem);

    // Find all description groups
    const descriptionGroups = $clonedFeaturedSpecItem.querySelectorAll('.product-description__description-group');
    // Create sections based on terms
    [{
      title: 'Product SKU',
      terms: ['Product']
    }, {
      title: 'Glass',
      terms: ['Crystal / Lens']
    }, {
      title: 'Water Resistance',
      terms: ['Water Resistance']
    }, {
      title: 'Dial',
      terms: ['Dial Color', 'Dial Markings']
    }, {
      title: 'Case',
      terms: ['Case Diameter', 'Case Height', 'Case Material', 'Case Color', 'Case Finish', 'Battery Type']
    }, {
      title: 'Strap',
      terms: ['Strap', 'Buckle']
    }, {
      title: 'Movement',
      terms: ['Watch Movement']
    }].forEach(({
      title,
      terms
    }) => findAndCreateSection(descriptionGroups, title, terms));
  }

  // Initialize section creation
  createSection(document.querySelector('.product-description'));

  // Enhance history methods to handle URL changes
  ['pushState', 'replaceState'].forEach(method => {
    const original = history[method];
    history[method] = function (state, ...rest) {
      const event = new Event(`on${method}`);
      event.state = state;
      window.dispatchEvent(event);
      return original.apply(history, [state, ...rest]);
    };
  });
  window.addEventListener('onpushState', handleUrlChange);
  window.addEventListener('onreplaceState', handleUrlChange);
  window.addEventListener('popstate', handleUrlChange);

  // Handle URL changes
  async function handleUrlChange() {
    dfc.log('URL changed to: ' + window.location.href);
    await dfc.sleep(1500);
    createSection(document.querySelector('.product-description'));
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1704
// CRO-1704 - D - Nav - Search Icon
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "100%",
  "experiment": "CRO-1704-d-nav-search-icon.js",
  "expId": "100-CRO-1704-d-nav-search-icon",
  "jiraCard": "CRO-1704"
}, async () => {
  // Only run on desktop and not on mobile
  if (window.innerWidth < 990) return;
  // Get the .header-search__button
  await dfc.find('.header__utility .header-search__button');
  const idx = window.dfc('100-CRO-1704-d-nav-search-icon');
  if (idx === 0) return;
  dfc.addStyle(` .header__utility .header-search__button .header-search__button-label { display: none !important; } .header__utility .header-search__button svg { width: 25px; height: 25px; } .header__utility .header-search__button { padding: 0 !important; width: 25px !important; background: none !important; border: none !important; } `);
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1756
// CRO - 1756 - A - Cart - Mini Cart Redesign
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "100%",
  "experiment": "CRO-1756-a-cart-mini-cart-redesign.js",
  "expId": "100-CRO-1756-a-cart-mini-cart-redesign",
  "jiraCard": "CRO-1756"
}, async () => {
  // Create variables for CTA's

  const $cartIcon = await dfc.find('.header__cart-toggle.header-icon');
  dfc.log('cartIcon', $cartIcon);

  // Prebucket to immediately assign idx, but wait to activate test
  const {
    idx,
    activate
  } = dfc.prebucket('100-CRO-1756-a-cart-mini-cart-redesign');

  // Set active test state
  let isTestActive = false;

  // If idx is variant, apply new styling to cart item
  if (idx === 1) {
    dfc.addStyle(` .mini-cart .cart-item { background-color: var(--theme-color-background-grey); padding: 24px; } .mini-cart .cart-item__quanity-input { background-color: var(--theme-color-background-grey); } .mini-cart .cart-item__quantity { width: 60%; } .mini-cart .cart-item__wrapper { margin-bottom: 0px; } .mini-cart .cart-item__image { padding: 0px; } .mini-cart .cart-item__extend-wrapper.ge-hide { justify-content: flex-end; border-top: none; padding: 0px; margin-top: 1rem; } .mini-cart .cart-item__extend-label { margin-right: 20px; font-size: 11px; } .mini-cart .cart-item__details-footer { display: block; } .mini-cart .cart-item__details-footer .cart-item__price-wrapper { margin-top: 16px; font-size: 14px; } @media (min-width: 425px) { .mini-cart .cart-item__details-footer { display: flex; } } @media (min-width: 425px) { .mini-cart .cart-item__details-footer .cart-item__price-wrapper { margin-top: 0px; } } @media (min-width: 768px) { .mini-cart .cart-item__extend-label { margin-right: 191px; font-size: 14px; } } @media (min-width: 990px) { .mini-cart .cart-item__extend-label { margin-right: 136px } } @media (min-width: 990px) { .mini-cart .cart-item__extend-wrapper.ge-hide { margin-top: 2rem; } } `);
  }
  const $cartIconParent = $cartIcon.parentElement;
  const $addToCartBtn = document.querySelector('.button.button--action.product-details__panel-add-btn.w1');

  // Set state of button click
  let isCartClicked = false;

  // Function to handle Cart on icon click
  const handleClick = () => {
    // If the button has already been clicked, exit.
    if (isCartClicked) return;

    // Otherwise set state to true on initial click
    isCartClicked = true;

    // Activate test on click if not already active
    if (!isTestActive) {
      activate();
      // Set state of test
      isTestActive = true;

      // Remove click events after initial click
      if (location.pathname.includes('/products/')) {
        $addToCartBtn.removeEventListener('click', handleClick);
      }
      $cartIconParent.removeEventListener('click', handleClick);
    }
  };

  // If on PDP, add click event to 'Add to Bag' CTA
  if (location.pathname.includes('/products/')) {
    $addToCartBtn.addEventListener('click', handleClick);
  }
  // Add click event to cart icon if it exists
  if ($cartIconParent) {
    $cartIconParent.addEventListener('click', handleClick);
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1775
// CRO-1775 - M - PLP - Product Card Redesign
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1775-m-plp-product-card-redesign.js",
  "expId": "ondeck-CRO-1775-m-plp-product-card-redesign",
  "jiraCard": "CRO-1775"
}, async () => {
  // Run on mobile only
  if (window.innerWidth > 768) return;
  // Run on PLP only
  if (!location.pathname.includes('/collections/')) return;
  await dfc.ready;
  const {
    idx,
    activate
  } = dfc.prebucket('ondeck-CRO-1775-m-plp-product-card-redesign');

  // Add styles
  const styleId = dfc.addStyle(` .product-card__image { width: 90% !important; } .product-card__image.product-card__image-jpg { width: 100% !important; } .product-card__media-wrapper ul.splide__pagination { bottom: -10px !important; top: auto !important; z-index: 50 !important; } .product-card__media-splide-pagination li { height: 5px !important; flex-grow: 0.1 !important; border-radius: 5px !important; } .product-card__media-splide-pagination li .splide__pagination__pageis-active, .product-card__media-splide-pagination li .splide__pagination__page.is-active { border-radius: 5px !important; } .product-card-quickbuy__actions-overlay .product-card__compare-holder, .product-card-quickbuy__actions-overlay button.button--secondary-inverted { width: 100% !important; min-width: 50% !important; } .product-card-quickbuy__actions-overlay hh-broadcast { display: none !important; } .product-card__details-specs span:first-child { display: none; } .product-card__details-specs span:nth-child(2)::before { display: none !important; } .product-card .product-card__media-wrapper .product-badge { top: 5px !important; right: auto !important; left: 5px !important; z-index: 50 !important; } .product-card .product-card__media-wrapper .product-badge .product-badge__item { background-color: rgba(255, 255, 255, 0.5) !important; border: 0 !important; } `);
  if (idx === 0) {
    document.getElementById(styleId).remove();
  }
  activate();
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1814
// CRO-1814 - A - PLP - Filter Closed
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "100%",
  "experiment": "CRO-1814-a-plp-filter-closed.js",
  "expId": "100-CRO-1814-a-plp-filter-closed",
  "jiraCard": "CRO-1814"
}, async () => {
  if (!location.pathname.includes('/collections/')) return;
  await dfc.ready;
  const $filtersForm = await dfc.find('#CollectionFiltersForm');

  // Prebucket to immediately assign idx, but wait to activate test
  const {
    idx,
    activate
  } = dfc.prebucket('100-CRO-1814-a-plp-filter-closed');

  // if desktop view
  if (window.innerWidth > 767) {
    if (idx === 1) {
      const $filterButtons = $filtersForm.querySelectorAll('.collection-filter-drawer__filter-summary.block');
      dfc.log($filterButtons);

      // Loop through each filter button
      $filterButtons.forEach($filterButton => {
        // Check if the filter button is active
        $filterButton.click();
      });
    }

    // Find 'Show Filter' button
    const $showFilterButton = document.querySelector('#main-collection-filters .collection-filters__filter-button.collection-filters__filter-button--desktop.js-toggle-filter-button');

    // Function to handle Show Filter click
    const handleClick = () => {
      // dfc.track('CRO-1814-show-filter-clicked');
      dfc.log('Desktop - Activate test');
      // Activate test on click if not already active
      activate();
      // Remove click event after initial click
      $showFilterButton.removeEventListener('click', handleClick);
    };

    // Add click event to 'Show Filter' button
    $showFilterButton.addEventListener('click', handleClick);
  }
  //  else {
  //   // Find 'Show Filter' button
  //   const $showFilterButton = document.querySelector(
  //     '#main-collection-filters .collection-filters__filter-button.collection-filters__filter-button--mobile.js-toggle-filter-button'
  //   );

  //   // Function to handle Show Filter click
  //   const handleClick = () => {
  //     dfc.track('CRO-1814-show-filter-clicked');
  //     // Activate test on click if not already active
  //     dfc.log('Mobile - Activate test');
  //     activate();

  //     // Remove click event after initial click
  //     $showFilterButton.removeEventListener('click', handleClick);
  //   };

  //   // Add click event to 'Show Filter' button
  //   $showFilterButton.addEventListener('click', handleClick);
  // }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1840
// CRO-1840 - D - Nav - Hover vs Click
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "LIVE",
  "experiment": "CRO-1840-d-nav-hover-vs-click.js",
  "expId": "CRO-1840-d-nav-hover-vs-click",
  "jiraCard": "CRO-1840"
}, async () => {
  // Only run on desktop
  if (window.innerWidth < 990) return;
  await dfc.ready;
  const $navParent = await dfc.find('.header-v2');
  const idx = window.dfc('CRO-1840-d-nav-hover-vs-click');
  if (idx === 0) return;
  dfc.addStyle(` .header-v2__menu-item--parent-wrapper .header-v2__nav { transition: none !important; }`);

  // find all the .header-v2__menu-item--parent-wrapper.js-header-mega-menu-parent elements in the nav, and add a hover event listener which toggles the .active class on the first div inside the parent\
  $navParent.querySelectorAll('.header-v2__menu-item--parent-wrapper.js-header-mega-menu-parent').forEach($navItem => {
    $navItem.addEventListener('mouseenter', () => {
      $navItem.querySelector('.header-v2__nav').classList.add('active');
    });
    $navItem.addEventListener('mouseleave', () => {
      $navItem.querySelector('.header-v2__nav').classList.remove('active');
    });
  });
});

// https://digitalfueltesting.atlassian.net/browse/CRO-953
// CRO-953 - D - Nav - Hide/Show Collections
// Portco: timex.com

dfc.run({
  "client": "timex.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-953-d-nav-hide-show-collections.js",
  "expId": "ondeck-CRO-953-d-nav-hide-show-collections",
  "jiraCard": "CRO-953"
}, async () => {
  // Only run on desktop
  if (window.innerWidth < 990) return;

  // get the announcment-bar__left-container element
  // await dfc.find('.announcment-bar__left-container');

  const idx = window.dfc('ondeck-CRO-953-d-nav-hide-show-collections');
  if (idx === 0) return;

  // dfc.log('$announcementBar', $announcementBar);

  // Add Style
  dfc.addStyle(` .announcment-bar__left-container > hh-button { display: none !important; } `);
});